*{
    margin:0;
    padding:0;
}

.title h1{
    font-size: 36px;
    font-family: 'Rock Salt', cursive;
    height: 100px;
    width: 800px;
    margin: 0 auto;
    text-align: center;
    color: rgb(136, 255, 0);
    background: -webkit-linear-gradient(rgb(147, 136, 247), rgb(3, 1, 17));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 6px 6px 0px rgba(138, 240, 155, 0.973);
    display: block;
    position: relative;
    z-index:-2;
}

.about, .projects, .contact {
    font-family: 'Press Start 2P', cursive;
    width: 800px;
    height: 100px;
    min-width: 33%;
    max-width: 33%;
    margin: 0 auto;
    text-align: center;
    color: rgb(136, 255, 0);
    background: -webkit-linear-gradient(rgb(147, 136, 247), rgb(3, 1, 17));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 6px 6px 0px rgba(138, 240, 155, 0.973);
    display: block;
    position: relative;
    float: left;
    z-index: -2;
    
}



@keyframes move-clouds-back{
    from {background-position: 0 0;}
    to {background-position: 10000px 0;}
}
@keyframes move-twinkle-back{
    from {background-position: 0 0;}
    to {background-position: -10000px 5000px;}
}

.stars, .twinkle, .clouds{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline;
    background-size: auto;
    background-repeat: no-repeat;
}

.stars{
    background: #000 url(assets/stars.jpg);
    z-index: -2;
    filter: brightness(.75)
    
}

.twinkle {
    background: transparent url(assets/twinkling.png) repeat top center;
    z-index: -1;
    animation: move-twinkle-back 200s linear infinite;
    opacity: .5;
}

.clouds {
    background: transparent url(assets/clouds.png) repeat;
    z-index: 1;
    opacity: .1;
    animation: move-clouds-back 200s linear infinite;
    background-size: cover;
}

